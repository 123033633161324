import type { FC } from 'react';
import React from 'react';
import { css } from '@compiled/react';
import { useQuery } from '@apollo/react-hooks';

import { token } from '@atlaskit/tokens';
import EmojiAddIcon from '@atlaskit/icon/core/emoji-add';
import { Flex } from '@atlaskit/primitives';
import { IconButton } from '@atlaskit/button/new';

import { SSRMouseEventWrapper, SSR_REACTIONS_BUTTON_METRIC } from '@confluence/browser-metrics';
import { usePageContentId } from '@confluence/page-context';

import { ReactionsPlaceholderQuery } from './graphql/ReactionsPlaceholderQuery.graphql';
import type {
	ReactionsPlaceholderQuery as ContentQueryType,
	ReactionsPlaceholderQueryVariables as ContentQueryVariables,
} from './graphql/__types__/ReactionsPlaceholderQuery';

const placeholderContainerStyles = css({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	cursor: 'not-allowed !important',
	marginTop: token('space.025'),
	marginBottom: token('space.025'),
	marginLeft: '0px',
});

const reactionPillStyles = css({
	width: 42,
	height: 24,
	background: token('color.skeleton.subtle'),
	borderRadius: 20,
	marginRight: token('space.050'),
});

const reactionIconStyles = css({
	width: 12,
	height: 12,
	background: token('color.skeleton.subtle'),
	borderRadius: 20,
	marginLeft: token('space.075'),
});

type ReactionsPlaceholderProps = {
	useAccuratePills?: boolean;
	onlyRenderPicker?: boolean;
	customAnalyticsAttributes?: Record<string, any>;
};

const DEFAULT_NUM_PILLS = 2;

export const ReactionsPlaceholder: FC<ReactionsPlaceholderProps> = ({
	useAccuratePills,
	onlyRenderPicker,
	customAnalyticsAttributes,
}) => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const isSSR = window.__SSR_RENDERED__ || process.env.REACT_SSR;
	const [contentId] = usePageContentId();
	const { data, loading } = useQuery<ContentQueryType, ContentQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		ReactionsPlaceholderQuery,
		{
			variables: { contentId: contentId! },
			// Only run this query if we're in SSR and useAccuratePills is set and not showing picker since doesn't use the pill UI
			skip: !isSSR || !useAccuratePills || !contentId || onlyRenderPicker,
		},
	);

	if (loading) {
		return null;
	}

	// Picker is only an icon and has no pills
	const renderPlaceholder = (numPills) =>
		onlyRenderPicker ? (
			<Flex justifyContent="center" alignItems="center" testId="reactions-picker-placeholder">
				<IconButton
					appearance="subtle"
					icon={EmojiAddIcon}
					label=""
					analyticsContext={{
						name: 'reactionsPicker',
						...customAnalyticsAttributes,
					}}
				/>
			</Flex>
		) : (
			<div css={placeholderContainerStyles} data-testid="reactions-placeholder">
				{Array(numPills)
					.fill('')
					.map((_, i) => (
						<span css={reactionPillStyles} key={i} data-testid="reactions-pill" />
					))}
				<span css={reactionIconStyles} data-testid="reaction-icon" />
			</div>
		);

	// We only need to show accurate pills in SSR
	if (isSSR) {
		const numDistinctReactions =
			data?.content?.nodes?.[0]?.contentReactionsSummary?.reactionsSummaryForEmoji.length;

		// If we get a count of reactions use it, otherwise fall back to the default
		const numReactionPills =
			numDistinctReactions !== undefined ? numDistinctReactions : DEFAULT_NUM_PILLS;

		return (
			<SSRMouseEventWrapper metricName={SSR_REACTIONS_BUTTON_METRIC} isPlaceholder>
				{renderPlaceholder(numReactionPills)}
			</SSRMouseEventWrapper>
		);
	} else {
		return renderPlaceholder(DEFAULT_NUM_PILLS);
	}
};
