import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { token } from '@atlaskit/tokens';
import EditorAddIcon from '@atlaskit/icon/core/add';
import Button from '@atlaskit/button';

import { SSRMouseEventWrapper, SSR_SHARE_BUTTON_METRIC } from '@confluence/browser-metrics';

import { i18n } from './i18n';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShareButtonWrapper = styled.div({
	display: 'inline-flex',
	outline: 'none',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles -- Ignored via go/DSP-18766
	cursor: 'not-allowed !important',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const InviteToEditPlaceholder = styled.div({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '32px',
	height: '32px',
	color: token('color.text'),
});

type ShareButtonPlaceholderProps = {
	showInviteToEditPlaceholder?: boolean;
	renderCustomPlaceholder?: () => React.ReactNode;
};

export const ShareButtonPlaceholder = ({
	showInviteToEditPlaceholder = false,
	renderCustomPlaceholder,
}: ShareButtonPlaceholderProps) => {
	return (
		<SSRMouseEventWrapper metricName={SSR_SHARE_BUTTON_METRIC} isPlaceholder>
			<ShareButtonWrapper data-testid="share-button-placeholder">
				{renderCustomPlaceholder ? (
					renderCustomPlaceholder()
				) : showInviteToEditPlaceholder ? (
					<InviteToEditPlaceholder data-testid="invite-to-edit-placeholder">
						<EditorAddIcon spacing="spacious" label="" />
					</InviteToEditPlaceholder>
				) : (
					<Button isDisabled>
						<FormattedMessage {...i18n.shareButtonText} />
					</Button>
				)}
			</ShareButtonWrapper>
		</SSRMouseEventWrapper>
	);
};

ShareButtonPlaceholder.displayName = 'ShareButtonPlaceholder';
