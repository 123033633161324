import gql from 'graphql-tag';

import { ContentOperationsFragment } from './EntityOperationsFragment.fragment';

export const ContentOperationsQuery = gql`
	query ContentOperationsQuery($contentId: ID!, $status: [String]) {
		content(id: $contentId, status: $status) {
			nodes {
				...ContentOperationsFragment
			}
		}
	}
	${ContentOperationsFragment}
`;
