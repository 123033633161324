/* eslint-disable check-file/filename-blocklist */
import type { MutationUpdaterFn } from 'apollo-client';
import type { DataProxy } from 'apollo-cache';

import type {
	ReactionsContentQuery,
	ReactionsContentQueryVariables,
	ReactionsContentQuery_content_nodes_contentReactionsSummary as ContentReactionsSummary,
} from './graphql/__types__/ReactionsContentQuery';
import type {
	ReactionsCommentsQuery,
	ReactionsCommentsQueryVariables,
	ReactionsCommentsQuery_comments_nodes_reactionsSummary as CommentsReactionsSummary,
} from './graphql/__types__/ReactionsCommentsQuery';
export type {
	ContainerType as GQLContainerType,
	GraphQLReactionContentType as GQLContentType,
} from './graphql/__types__/SaveReactionMutation';

export enum ReactionLocation {
	PAGE = 'page',
	BLOGPOST = 'blogpost',
	PAGE_COMMENT = 'page-comment',
	INLINE_COMMENT = 'inline-comment',
	EDITOR_INLINE_COMMENT = 'editor-inline-comment',
	FEED_PAGE = 'feed-page',
	FEED_BLOGPOST = 'feed-blogpost',
	FEED_COMMENT = 'feed-comment',
}

export enum ReactionContainerType {
	PAGE = 'page',
	BLOGPOST = 'blogpost',
	SPACE = 'space',
}

export type ReactionResponse = {
	ari: string;
	containerAri: string;
	emojiId: string;
};

export type Reaction = {
	ari: string; // cloudId
	containerAri: string; // contentId
	emojiId: string;
	count: number;
	reacted: boolean;
	users?: ReactedUser[];
	optimisticallyUpdated?: boolean;
};

export type ReactedUser = {
	id: string;
	displayName: string;
};

export type ReactionsData = ContentReactionsSummary | CommentsReactionsSummary | null;

export type QueryData = ReactionsContentQuery | ReactionsCommentsQuery;
export type QueryVariables = ReactionsContentQueryVariables | ReactionsCommentsQueryVariables;

export type ReactedUsersMap = {
	[key: string]: ReactedUser[];
};

export type ReactionMutationUpdateFn = (
	emojiId: string,
	actionType: 'add' | 'delete',
	contentId: string,
	containerId?: string,
	cache?: DataProxy,
) => MutationUpdaterFn<any> | void;
