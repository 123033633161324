import { LoadableAfterPaint } from '@confluence/loadable';

export const UnreadCommentCount = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-UnreadCommentCount" */ './UnreadCommentCount'))
			.UnreadCommentCount,
});

export const UnreadTooltipText = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-UnreadTooltipText" */ './UnreadTooltipText'))
			.UnreadTooltipText,
});

export {
	useUnreadComments,
	useUnreadCommentsActions,
	useUnreadCommentsState,
} from './hooks/useUnreadComments';

export { useUnreadCommentsForInlineComment } from './hooks/useUnreadCommentsForInlineComment';

export {
	handleAddedInlineCommentForUnread,
	handleObliteratedInlineCommentForUnread,
} from './utils/unreadCommentsPubSubUtils';

export type {
	UnreadPubSubParams,
	ThreadKeyToCommentIdMap,
	UnreadComment,
} from './types/unreadCommentsTypes';

export { isValidUnreadComment } from './utils/unreadCommentCountUtils';
export type { UnreadCommentQuery_comments_nodes as UnreadCommentNode } from './__types__/UnreadCommentQuery';
