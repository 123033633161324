import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { WebItemAnalyticsWrapper } from '@confluence/analytics';
import { CONTENT_METADATA_JIRA_ID } from '@confluence/web-item';

const JIRA_ANALYTICS = {
	type: 'sendUIEvent',
	data: {
		action: 'clicked',
		source: 'viewPageScreen',
		actionSubject: 'JiraTicketsButton',
	},
};

export function JiraButtonAnalyticsWrapper({ children }) {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const container = useRef(null);

	useEffect(() => {
		setTimeout(() => {
			let linkVisible = false;
			const link = container?.current?.children?.[CONTENT_METADATA_JIRA_ID];
			if (link && !link.classList.contains('hidden')) {
				linkVisible = true;
			}

			if (linkVisible) {
				createAnalyticsEvent({
					type: 'sendTrackEvent',
					data: {
						actionSubject: 'JiraTicketsButton',
						action: 'shown',
						source: 'viewPageScreen',
						attributes: {
							isPageSSRd: window.__SSR_RENDERED__,
						},
					},
				}).fire();
			}
		}, 5000); // should be enough for render after the plugin has mounted
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<WebItemAnalyticsWrapper analyticsData={JIRA_ANALYTICS} innerRef={container} wrapChildren>
			{children}
		</WebItemAnalyticsWrapper>
	);
}

JiraButtonAnalyticsWrapper.propTypes = {
	children: PropTypes.node,
};
